// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * WP Caption
\* ------------------------------------------------------------------------ */

.wp-caption {
    & {
        background: $light;
        border-radius: remify(5, 16);
        margin: remify(0 10 10 0, 16);
        padding: remify(15, 16);
        width: 100%;
    }
}

.wp-caption_text {
    & {
        color: $dark;
        margin: remify(15 0 0, 13);
        text-align: center;
    }
}
