// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu Toggle
\* ------------------------------------------------------------------------ */

.menu-toggle {
    & {
        background: none;
        border: 0;
        color: $accent;
        font-size: remify(16, 16);
        height: remify(60, 16);
        margin: 0;
        padding: 0;
        position: relative;
        text-indent: -999em;
        width: remify(60, 16);
    }

    &::before,
    &::after {
        background: currentColor;
        bottom: 0;
        content: "\0020";
        display: block;
        height: remify(3, 16);
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        transition: box-shadow 0.15s 0.15s, top 0.15s 0.15s, transform 0.15s;
        width: remify(20, 16);
    }

    &.-rounded::before,
    &.-rounded::after {
        border-radius: remify(1.5, 16);
    }

    &.is-active::before,
    &.is-active::after {
        transition: box-shadow 0.15s, top 0.15s, transform 0.15s 0.15s;
    }

    &::before {
        box-shadow: remify(0 8 0 0, 16) currentColor;
        top: remify(-16, 16);
    }

    &.is-active::before {
        box-shadow: 0 0 0 0 transparent;
        top: 0;
        transform: rotate(45deg);
    }

    &::after {
        top: remify(16, 16);
    }

    &.is-active::after {
        top: 0;
        transform: rotate(-45deg);
    }
}
