// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Logo
\* ------------------------------------------------------------------------ */

.logo {
    & {
        display: block;
        text-decoration: none;
        transition: opacity 0.15s;
    }

    &:hover {
        opacity: 0.9;
    }
}

.logo_image {
    & {
        display: block;
        width: 100%;
    }
}
