// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    @media screen and (max-width: $xl-break) {
        font-size: 1.25vw;
    }
}

.header_inner {
    & {
        padding: remify(($content-padding * 0.5) $content-padding ($content-padding * 2));
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        padding: remify(($content-padding * 0.5) $content-padding ($content-padding * 2));
    }

    > .header_inner {
        padding: 0;
    }
}
