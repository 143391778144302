// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Button
\* ------------------------------------------------------------------------ */

.button {
    & {
        background: $accent;
        border: 0;
        border-radius: remify(5, 18);
        color: $dark;
        cursor: pointer;
        display: inline-block;
        font-display: swap;
        font-family: $heading-font-alt;
        font-size: remify(18, 16);
        font-weight: 400;
        line-height: remify(22.5, 18);
        margin: remify(0 0 10, 14);
        outline: 0;
        padding: remify(7.5 22.5, 18);
        text-decoration: none;
        text-transform: uppercase;
        transition: background-color 0.15s, color 0.15s;
    }

    &:focus,
    &:hover {
        background: $accent_alt;
        color: $primary;
    }
}
