// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu List
\* ------------------------------------------------------------------------ */

/* ============================ GLOBAL VARIANTS =========================== */

.menu-list_container {
    & {
        clear: fix;
    }
}

.menu-list {
    & {
        clear: fix;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
    }
}

.menu-list_item {
    & {
        float: left;
        position: relative;
    }

    &.-parent {
        display: flex;
        flex-wrap: wrap;
    }

    &.-mega {
        position: static;
    }
}

.menu-list_link {
    & {
        display: block;
        flex: 1 1 0%;
        min-width: 0;
        text-decoration: none;
    }
}

.menu-list_toggle {
    & {
        background: none;
        border: 0;
        cursor: pointer;
        display: block;
        flex: 0 0 auto;
        font-size: 1em;
        margin: 0;
    }

    > .icon {
        transition: transform 0.15s;
    }

    .menu-list_item.is-active > & > .icon {
        transform: rotate(-180deg);
    }
}

/* between variant */

.menu-list.-between {
    & {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    > .menu-list_item {
        flex: 0 0 auto;
        float: none;
    }

    &::after {
        flex: 0 0 100%;
    }
}

/* center variant */

.menu-list.-center {
    & {
        float: right;
        position: relative;
        right: 50%;
    }

    > .menu-list_item {
        left: 50%;
    }
}

/* flex variant */

.menu-list.-flex {
    & {
        display: flex;
    }

    > .menu-list_item {
        flex: 1 1 0%;
        min-width: 0;
    }
}

/* vertical variant */

.menu-list.-vertical {
    & {
        text-align: left;
    }

    > .menu-list_item {
        float: none;
    }
}

/* vertical reverse variant */

.menu-list.-vertical.-reverse {
    & {
        text-align: right;
    }
}

/* tier variant */

.menu-list[class*="-tier"] {
    & {
        min-width: 100%;
    }
}

/* tier accordion variant */

.menu-list[class*="-tier"].-accordion {
    & {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.25s, visibility 0s 0.25s;
        visibility: hidden;
        width: 100%;
    }

    .menu-list_item.-parent.is-active > & {
        max-height: remify(500, 16);
        transition: max-height 0.25s;
        visibility: visible;
    }
}

/* tier overlay variant */

.menu-list[class*="-tier"].-overlay {
    & {
        left: -999em;
        opacity: 0;
        position: absolute;
        top: -999em;
        transition: left 0s 0.15s, opacitwy 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        visibility: hidden;
        width: remify(250, 16);
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        opacity: 1;
        transition: opacity 0.15s;
        visibility: visible;
    }
}

/* tier variant */

.menu-list[class*="-tier"].-overlay.-reverse {
    & {
        transition: opacity 0.15s, right 0s 0.15s, top 0s 0.15s;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 0;
        transition: opacity 0.15s;
    }
}

/* tier1 overlay variant */

.menu-list.-tier1.-overlay {
    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: 0;
        top: 100%;
    }

    > .menu-list_item.is-active > .menu-list_toggle > .icon {
        transform: rotate(-90deg);
    }
}

/* tier1 overlay reverse variant */

.menu-list.-tier1.-overlay.-reverse {
    & {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 0;
    }

    > .menu-list_item.is-active > .menu-list_toggle > .icon {
        transform: rotate(90deg);
    }
}

/* tier2 overlay variant */

.menu-list.-tier2.-overlay {
    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: 100%;
        top: 0;
    }
}

/* tier2 overlay reverse variant */

.menu-list.-tier2.-overlay.-reverse {
    & {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 100%;
    }
}

/* ============================ CUSTOM VARIANTS =========================== */

/* callouts variant */

.menu-list.-callouts {
    & {
        color: $tertiary;
        font-display: swap;
        font-family: $heading-font;
        font-size: remify(68, 16);
        font-weight: 700;
        line-height: remify(85, 68);
        text-align: left;
        text-transform: uppercase;
    }

    &.-vertical {
        text-align: center;
    }

    .menu-list_item {
        & {
            padding: remify(10 0, 45);
        }

        &:nth-child(1),
        &:nth-child(4n+1) {
            font-size: remify(90, 68);
            padding: remify(10 0, 90);
        }

        &:nth-child(2),
        &:nth-child(4n+2) {
            font-family: $heading-font-alt;
            font-size: remify(50, 68);
            font-weight: 400;
        }

        &:nth-child(3),
        &:nth-child(4n+3) {
            font-size: remify(68, 68);
        }

        &:nth-child(4),
        &:nth-child(4n+4) {
            font-size: remify(46, 68);
        }

        &:nth-child(even) {
            background: url("../media/background-divider_left-top.png") left top no-repeat, url("../media/background-divider_right-top.png") right top no-repeat, url("../media/background-divider.png") remify(38 16, 45) / calc(100% - #{remify(38 * 2, 45)}) remify(2.5, 45) no-repeat, url("../media/background-divider_left-bottom.png") left bottom no-repeat, url("../media/background-divider_right-bottom.png") right bottom no-repeat, url("../media/background-divider.png") remify(38, 45) calc(100% - #{remify(16, 45)}) / calc(100% - #{remify(38 * 2, 45)}) remify(2.5, 45) no-repeat;
            padding-bottom: remify(15, 45);
            padding-top: remify(18, 45);
        }
    }

    .menu-list_link {
        color: $tertiary;
    }

    .menu-list_link:hover {
        color: $tertiary_alt;
    }

    .menu-list_item {
        @include each(1) {
            .menu-list_link {
                color: $accent;
            }

            .menu-list_link:focus,
            .menu-list_link:hover {
                color: $accent_alt;
            }
        }

        @include each(2) {
            .menu-list_link {
                color: $light;
            }

            .menu-list_link:focus,
            .menu-list_link:hover {
                color: $light_alt;
            }
        }

        @include each(3) {
            .menu-list_link {
                color: $tertiary;
            }

            .menu-list_link:focus,
            .menu-list_link:hover {
                color: $tertiary_alt;
            }
        }
    }
}

/* mega variant */

.menu-list_container.-mega {
    & {
        display: flex;
        left: -999em;
        opacity: 0;
        position: absolute;
        right: 999em;
        top: -999em;
        transition: left 0s 0.15s, opacity 0.15s, right 0s 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        visibility: hidden;
    }

    .menu-list_item.-mega.-parent:hover > &,
    .menu-list_item.-mega.-parent.is-active > & {
        left: 0;
        opacity: 1;
        right: 0;
        top: 100%;
        transition: opacity 0.15s;
        visibility: visible;
    }

    .menu-list.-tier1 {
        flex: 1 1 0%;
        min-width: 0;
    }

    .menu-list.-tier2 {
        flex: 0 0 100%;
    }
}

/* meta variant */

.menu-list.-meta {
    & {
        color: $foreground_alt;
        font-display: swap;
        font-family: $heading-font;
        font-size: remify(14, 16);
        font-weight: 700;
        text-align: left;
        text-transform: uppercase;
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(20, 14);
    }

    .menu-list_link {
        color: $foreground_alt;
        display: inline-block;
    }

    .menu-list_link:hover {
        color: lighten($foreground_alt, 10);
    }
}

/* navigation variant */

.menu-list.-navigation {
    & {
        background: transparentize($primary, 0.5);
        border: remify(3) solid transparentize(darken(desaturate(adjust-hue($accent, -4.2522), 7.817), 6.8627), 0.6);
        border-radius: remify(18, 20);
        color: lighten(desaturate(adjust-hue($accent, 4.8115), 41.0158), 28.0392);
        font-display: swap;
        font-family: $heading-font-alt;
        font-size: remify(20, 16);
        font-weight: 400;
        text-transform: uppercase;
    }

    &:not(.-vertical) {
        display: flex;
    }

    .menu-list_item {
        flex: 0 0 auto;
    }

    .menu-list_item.-logo {
        flex: 1 1 0%;
        position: static;
    }

    .menu-list_link {
        color: lighten(desaturate(adjust-hue($accent, 4.8115), 41.0158), 28.0392);
        padding: remify(10 12.5, 20);
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        color: darken(desaturate(adjust-hue($accent, -15.5466), 12.1194), 3.1373);
    }

    .menu-list_logo {
        left: 50%;
        position: absolute;
        top: remify(-60, 20);
        transform: translateX(-50%);
        width: remify(290, 20);
    }
}

/* pagination variant */

.menu-list.-pagination {
    & {
        font-display: swap;
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 300;
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(8, 14);
    }

    .menu-list_link.-current {
        color: $foreground;
        cursor: auto;
    }
}

/* social variant */

.menu-list.-social {
    & {
        font-size: remify(16, 16);
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(10, 16);
    }

    .menu-list_link {
        background: $accent;
        border-radius: remify(4, 16);
        color: $light;
        padding: remify(2 4, 16);
        transition: background-color 0.15s;
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        background-color: $accent_alt;
        color: $light;
    }

    .menu-list_item.-facebook > .menu-list_link {
        background-color: #3B5998;
    }

    .menu-list_item.-facebook > .menu-list_link:focus,
    .menu-list_item.-facebook > .menu-list_link:hover {
        background-color: #355089;
    }

    .menu-list_item.-twitter > .menu-list_link {
        background-color: #4AB3F4;
    }

    .menu-list_item.-twitter > .menu-list_link:focus,
    .menu-list_item.-twitter > .menu-list_link:hover {
        background-color: #1DA1F2;
    }
}

/* sitemap variant */

.menu-list.-sitemap {
    & {
        color: $accent;
        font-display: swap;
        font-family: $body-font;
        font-size: remify(12, 16);
        font-weight: 300;
        line-height: remify(18.46152, 12);
        text-transform: uppercase;
    }

    .menu-list_item {
        display: inline-block;
        float: none;
    }

    .menu-list_item:not(:last-child)::after {
        content: "\002D";
        display: inline-block;
        margin: remify(0 2.5, 12);
    }

    .menu-list_item.-title {
        margin-right: remify(2.5, 12);
    }

    .menu-list_item.-title::after {
        display: none;
    }

    .menu-list_link {
        color: $accent;
        display: inline-block;
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        color: $accent_alt;
    }
}

/* toolbar variant */

.menu-list.-toolbar {
    & {
        color: darken(desaturate(adjust-hue($accent, -15.5466), 12.1194), 3.1373);
        font-display: swap;
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 300;
        text-transform: uppercase;
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(10, 14);
    }

    .menu-list_link {
        color: darken(desaturate(adjust-hue($accent, -15.5466), 12.1194), 3.1373);
    }

    &.-vertical .menu-list_link {
        padding: remify(6.25 12.5, 14);
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        color: darken(saturate(adjust-hue($accent, -15.5615), 22.0472), 0.1961);
    }
}
