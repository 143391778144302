// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Gallery
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

.gallery {
    & {
        display: flex;
        flex-wrap: wrap;
        margin: remify(32, 16);
        padding: 0;
        width: 100%;
    }
}

@for $i from 1 through 9 {
    .gallery-columns-#{$i} .gallery-item {
        flex: 0 1 (100% / $i);
    }
}

/* stylelint-enable */
