// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content_menu-list_container {
    @media screen and (max-width: $l-break) {
        .menu-list.-callouts {
            font-size: 6.640625vw;
        }
    }
}
