// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
    }

    @media screen and (max-width: $xs-break - (1 / 16)) {
        text-align: center;
    }
}

.footer_inner {
    & {
        background: $page_background;
        box-shadow: remify(0 -5 5 0, 16) transparentize($dark, 0.25);
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.75) $content-padding);
    }
}

.footer_text {
    & {
        color: $accent;
        font-size: remify(12, 16);
        text-transform: uppercase;
    }
}

/* fullbleed variant */

.footer-block.-fullbleed {
    & {
        background: $page_background;
        box-shadow: remify(0 -5 5 0, 16) transparentize($dark, 0.25);
        padding: remify(($content-padding * 0.75) $content-padding);
    }

    > .footer_inner {
        background: none;
        box-shadow: none;
        padding: 0;
    }

    > .footer_inner.-fullbleed {
        max-width: none;
    }
}
