// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Colors
\* ------------------------------------------------------------------------ */

$primary: #320000;
$primary_alt: #680000;

$secondary: #010B17;
$secondary_alt: #00244E;

$tertiary: #455023;
$tertiary_alt: #6F8821;

$accent: #E39E1D;
$accent_alt: #FFB937;

$light: #FFFFFF;
$light_alt: #EEEEEE;

$dark: #000000;
$dark_alt: #111111;

$foreground: #FFFFFF;
$foreground_alt: #EEEEEE;

$background: #010B17;
$background_alt: #00244E;

$page_background: #320000;
$page_background_alt: #680000;

$warning: #9F0000;
$warning_alt: #F83636;

/* ------------------------------------------------------------------------ *\
 * Fonts                                                                    *
\* ------------------------------------------------------------------------ */

$heading-font: "Bernard MT Condensed", "Times New Roman", "Times", serif;
$heading-font-alt: "Kreon", "Rockwell", "Times New Roman", "Times", serif;
$body-font: "museo-sans", "Helvetica", "Arial", sans-serif;

/* ------------------------------------------------------------------------ *\
 * Widths                                                                   *
\* ------------------------------------------------------------------------ */

$site-width: 1024;
$column-gap: 20;
$content-padding: 25;

/* ------------------------------------------------------------------------ *\
 * Break Points                                                             *
\* ------------------------------------------------------------------------ */

$xxxl-break: 1440em / 16;
$xxl-break: 1366em / 16;
$xl-break: 1280em / 16;
$l-break: 1024em / 16;
$m-break: 960em / 16;
$s-break: 853em / 16;
$xs-break: 768em / 16;
$xxs-break: 640em / 16;
$xxxs-break: 480em / 16;

/* ------------------------------------------------------------------------ *\
 * Layers                                                                   *
\* ------------------------------------------------------------------------ */

$layers: (
    header: 4,
    navigation: 3,
    content: 1,
    footer: 2,
);
