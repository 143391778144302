// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Fonts
\* ------------------------------------------------------------------------ */

@font-face {
    font-family: "Bernard MT Condensed";
    font-style: normal;
    font-weight: 700;
    src: url("../media/bernard-mt-condensed/700/webfont.woff2") format("woff2"), url("../media/bernard-mt-condensed/700/webfont.woff") format("woff");
}
