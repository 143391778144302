// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Featured Image
\* ------------------------------------------------------------------------ */

.featured-image {
    & {
        background: transparentize($primary, 0.5);
        border: remify(3) solid transparentize(darken(desaturate(adjust-hue($accent, -4.2522), 7.817), 6.8627), 0.6);
        border-radius: remify(18, 16);
        display: block;
        margin: 0;
        position: relative;
    }
}

.featured-image_image {
    & {
        border-radius: remify(15, 16);
        display: block;
        width: 100%;
    }
}

.featured-image_title {
    & {
        bottom: remify(15, 42);
        font-size: remify(42, 16);
        left: 0;
        margin: 0;
        padding: remify(10 45 7, 42);
        position: absolute;
        right: 0;
        text-align: center;
        text-shadow: remify(0 4 4.5, 42) transparentize($dark, 0.85);
    }

    &::before,
    &::after {
        bottom: 0;
        content: "\0020";
        display: block;
        position: absolute;
    }

    &::before {
        height: remify(88, 42);
        width: remify(72, 42);
    }

    &::after {
        height: remify(69, 42);
        width: remify(26, 42);
    }

    &.-left {
        background: $tertiary;
        background: url("../media/background-banner_left_core.svg") left top / 100% 100% no-repeat;
        color: $light;
        right: remify(5, 42);
    }

    &.-left::before {
        background: url("../media/background-banner_left_wrap.svg") left top / 100% 100% no-repeat;
        left: remify(-39, 42);
    }

    &.-left::after {
        background: url("../media/background-banner_left_edge.svg") right top / 100% 100% no-repeat;
        right: remify(-26, 42);
    }

    &.-right {
        background: $accent;
        background: url("../media/background-banner_right_core.svg") right top / 100% 100% no-repeat;
        color: lighten(desaturate(adjust-hue($primary, 358.9474), 27.8481), 21.1765);
        left: remify(5, 42);
    }

    &.-right::before {
        background: url("../media/background-banner_right_wrap.svg") right top / 100% 100% no-repeat;
        right: remify(-39, 42);
    }

    &.-right::after {
        background: url("../media/background-banner_right_edge.svg") left top / 100% 100% no-repeat;
        left: remify(-25.5, 42);
    }
}
