// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Text
\* ------------------------------------------------------------------------ */

.text {
    & {
        color: $foreground;
        font-display: swap;
        font-family: $body-font;
        font-size: remify(16, 16);
        font-weight: 300;
        line-height: remify(24, 16);
        margin: remify(0 0 24, 16);
    }

    .button {
        font-size: remify(18, 16);
    }
}

/* label variant */

.text.-label {
    & {
        cursor: pointer;
    }
}

/* list variant */

.text.-list {
    & {
        list-style: disc;
        padding: remify(0 0 0 16, 12);
    }

    .text_list-item {
        display: list-item;
    }
}
