// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Gallery
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

.gallery-item {
    & {
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
        padding: remify(24, 16);
        width: 100%;
    }
}

/* stylelint-enable */
