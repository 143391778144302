// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");
    }
}

.content_inner {
    & {
        background: url("../media/background-content.jpg") center top no-repeat $secondary;
        margin: 0 auto;
        max-width: remify($site-width);
    }
}

.content_post {
    & {
        padding: remify(($content-padding * 1.25) ($content-padding * 1.75));
    }
}

.content_sidebar {
    & {
        background: transparentize($secondary, 0.4);
        padding: remify(($content-padding * 1.25) ($content-padding * 0.5));
    }
}

.content_menu-list_container {
    @media screen and (max-width: $xs-break) {
        .menu-list.-callouts {
            font-size: 14.16666666666667vw;
        }
    }
}

/* fullbleed variant */

.content-block.-fullbleed {
    & {
        background: url("../media/background-content.jpg") center top no-repeat $secondary;
    }

    > .content_inner {
        background: none;
    }

    > .content_inner.-fullbleed {
        max-width: none;
    }
}
