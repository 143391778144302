// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
    }
}

.header_inner {
    & {
        background: url("../media/background-header.jpg") center top no-repeat $primary;
        box-shadow: remify(0 5 5 0, 16) transparentize($dark, 0.25);
        margin: 0 auto;
        max-width: remify($site-width);
        padding: 0;
        position: relative;
    }
}

.header_row {
    &.-padded {
        width: calc(100% + #{remify(35, 16)});
    }

    &.-padded > [class^="col-"] {
        padding-right: remify(35, 16);
    }
}

.header_logo {
    & {
        height: auto;
        left: 50%;
        max-height: 100%;
        max-width: remify(100, 16);
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;
    }

    .logo_image {
        max-height: 100%;
        max-width: 100%;
    }
}

.header_menu-list_container {
    & {
        position: relative;
        z-index: 999;
    }

    .menu-list.-navigation {
        margin: remify(0 0 30, 16);
    }

    .menu-list.-social {
        margin: remify(10 0 15, 16);
    }
}

.header_title {
    & {
        align-items: center;
        background: darken(saturate(adjust-hue(#455023, 18.9333), 26.659), 7.6471);
        background: linear-gradient(to bottom, lighten(saturate(adjust-hue($tertiary, -6.7255), 1.8334), 10) 0%, darken(saturate(adjust-hue($tertiary, 18.9333), 26.659), 7.6471) 100%);
        color: $light;
        display: flex;
        font-size: remify(16, 16);
        justify-content: center;
        margin: 0;
        padding: remify(10 11.25 7, 16);
        text-align: center;
        text-shadow: remify(0 2 2.25, 16) transparentize($dark, 0.85);
        width: 100%;
    }

    &.-left {
        padding-right: remify(30, 16);
    }

    &.-right {
        padding-left: remify(30, 16);
    }

    &.-alt {
        background: darken(saturate(adjust-hue($accent, 5.5396), 2.9237), 0.9804);
        background: linear-gradient(to bottom, darken(desaturate(adjust-hue($accent, -2.2488), 7.5824), 2.549) 0%, darken(saturate(adjust-hue($accent, 5.5396), 2.9237), 0.9804) 100%);
        color: lighten(desaturate(adjust-hue($primary, 358.9474), 27.8481), 21.1765);
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        background: url("../media/background-header.jpg") center top no-repeat $primary;
        box-shadow: remify(0 5 5 0, 16) transparentize($dark, 0.25);
        padding: 0;
    }

    > .header_inner {
        background: none;
        box-shadow: none;
        padding: 0;
    }

    > .header_inner.-fullbleed {
        max-width: none;
    }
}
